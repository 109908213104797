<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9">
        <v-row class="rowBg">
          <v-col cols="12">
            <h5>
              <v-icon size="25" color="#2776ED" class="mr-4"
                >mdi-information</v-icon
              >
              We have launched all new messages!!
            </h5>
          </v-col>
        </v-row>

        <v-row class="rowBg mt-10">
          <v-col cols="12">
            <v-layout class="messageRow">
              <v-col
                cols="3"
                style="border-right: 1px outset #c2c2c2 !important;"
              >
                <v-icon size="25" color="grey" class="mr-2"
                  >mdi-android-messages</v-icon
                >
                Messages
              </v-col>
              <v-col cols="9"> </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";

export default {
  name: "MessageCenter",

  components: {
    RetailerMenu,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Message Center",
        disabled: true,
        href: "/",
      },
    ],
  }),
  methods: {},
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.messageRow {
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  border: 1px outset #c2c2c2 !important;
}
</style>
